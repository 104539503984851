$(function() {
	$(window).on("scroll", function() {
		if($(window).scrollTop() > 100) {
			$(".sidebar-wrapper-1").addClass("teste1");
			$(".sidebar-wrapper-1 .logo-vermelho").removeClass("hidden");
			$(".sidebar-wrapper-1 .logo-branca").addClass("hidden");
		} else {
			$(".sidebar-wrapper-1").removeClass("teste1");
			$(".sidebar-wrapper-1 .logo-branca").removeClass("hidden");
			$(".sidebar-wrapper-1 .logo-vermelho").addClass("hidden");
		}
	});

	if($('.owl-carousel').length){
		$('.owl-carousel').owlCarousel({
			loop:true,
			margin:10,
			responsiveClass:true,
			responsive:{
				0:{
					items:1,
					nav:true
				},
				600:{
					items:2,
					nav:false
				},
				1000:{
					items:3,
					nav:true,
					loop:false
				}
			}
		});		
	}

    // tooltip
	$('[data-toggle="tooltip"]').tooltip();   

    if($('.sendContato').length){
        $('.rtnEmail').hide();
        $('.sendContato').on('submit', function(){
            var action  = $(this).attr('action');
            var method  = $(this).attr('method')
            var form  = $(this);

            $('.rtnEmail').html('<i class="fa fa-spinner fa-pulse"></i> Enviando...').show();

            $.ajax({
                url: action,
                type: method,
                dataType: 'json',
                data: form.serialize(),
                error: function() {
                    $('.rtnEmail').hide();
                    alert('Error: Falha: tente novamente ou contate o administrador!');
                },
                success: function(data) {
                    $('.rtnEmail').addClass(data.return);
                    $('.rtnEmail').html(data.message);
                }
            });
            return false;
        });
    }

});